/* istanbul ignore file */
import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Skeleton, Form, Input, Collapse,Breadcrumb,Divider,InputNumber, Modal, Row, Spin} from "antd";
import { ReactGrid } from "@silevis/reactgrid";
import { Helmet } from "react-helmet";
import "../component/reactgrid/styles.scss";
import {
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import Notification from "../services/Notification";
import Layout from "../component/layout/Layout";
import SkeletonLoading from "../component/SkeletonLoading";
import { GridDropdownCellTemplate } from "../component/rateCell/GridDropdownCellTemplate";
import { applyChangesToData } from "../utils/common";
import { getAddressTDS, postReceivedDeliveryTPV, postPublishDeliveryTPV, postUpdatePurchaseOrder, getDeliveryTPV } from '../services/MegaforceUnitDelivery'
import { itemRender } from '../utils/common'
import { useNavigate, useParams } from 'react-router'

const itemMenu = [
   {
     href: `/megaforce-unit-delivery`,
     title: (
       <>
         <span>Megaforce Unit Delivery</span>
       </>
     )
   },
   {
     title: (
       <>
         <span>Megaforce Unit Delivery Detail</span>
       </>
     )
   }
]
const HEADING_ROW_HEIGHT = 40;
const headerRow = {
  height: HEADING_ROW_HEIGHT,
  rowId: "header",
  cells: [
    { type: "header", text: "HBL#" },
    { type: "header", text: "MBL#" },
    { type: "header", text: "Discharge Port" },
    { type: "header", text: "Destination" },
    { type: "header", text: "Container#" },
    { type: "header", text: "ETD" },
    { type: "header", text: "ETA Door" },
    { type: "header", text: "TDS Warehouse" },
  ],
};
const headerRow2 = {
  height: HEADING_ROW_HEIGHT,
  rowId: "header",
  cells: [
    { type: "header", text: "Item" },
    { type: "header", text: "Serial No." },
    { type: "header", text: "Shipping No." },
  ],
};
const getColumns = () => [
  { columnId: "hbl", width: 200},
  { columnId: "mbl", width: 195 },
  { columnId: "dischargePort", width: 195 },
  { columnId: "destination", width: 195 },
  { columnId: "container", width: 195 },
  { columnId: "etd", width: 195 },
  { columnId: "etaDoor", width: 195 },
  { columnId: "tdsWarehouse", width: 195 },
];

const getColumns2 = () => [
  { columnId: "no", width: 75},
  { columnId: "serialNo", width: 350 },
  { columnId: "shippingNo", width: 350 },
];

const App = (props) => {
  const [form] = Form.useForm();
  const initialized = useRef(false);
  const [editMode, setEditMode] = useState(true);
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [dataItem, setDataItem] = useState([{
    hbl:null,
    mbl:null,
    dischargePort:null,
    destination:null,
    container:null,
    etd:null,
    etaDoor:null,
    tdsWarehouse:null,
  }]);
  const [dataItem2, setDataItem2] = useState(
    [
        [
           {
              "no":"1",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"2",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"3",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"4",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"5",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"6",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"7",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"8",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"9",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"10",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"11",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"12",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"13",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"14",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"15",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"16",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"17",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"18",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"19",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"20",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"21",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"22",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"23",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"24",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"25",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"26",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"27",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"28",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"29",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"30",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"31",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"32",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"33",
              "serialNo":null,
              "shippingNo":null
           },
           {
              "no":"34",
              "serialNo":null,
              "shippingNo":null
           }
        ],
    ]);
  const [logingApi, setLogingApi] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [editId, setEditId] = useState();
  const draftName = useRef("");
  const Noti = new Notification()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSaveOpen, setIsSaveOpen] = useState(false)
  const [modalData, setModalData] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [ddlTds, setDdlTds] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [saveLoading, setSaveLoading] = useState(false);
  const [dName, setDName] = useState("");

  useEffect(
     () => {
      if (!initialized.current) {
        initialized.current = true;
        fncGetAddressTDS()
        if(id) {
          setEditId(id)
          fncGetDeliveryTPV()
          setEditMode(false)
        }
        setLoadingFirst(false);
      }
    },
    []
  );

 const fncGetDeliveryTPV = () => {
  getDeliveryTPV({"refId" : id, status:"draft"}).then(res =>{
    let data1 = []
    let data2 = []
    for (let d of res.data.resData[0].detail) {
      data1.push({
        "container": d.container,
        "destination": d.destination,
        "dischargePort": d.dischargePort,
        "etaDoor": d.etaDoor,
        "etd": d.etd,
        "hbl": d.hbl,
        "mbl": d.mbl,
        "tdsWarehouse": d.tdsWarehouse,
      })
      data2.push(d.listSerialNo)
    }
    setDataItem(data1)
    setDataItem2(data2)
    form.setFieldValue("qty",res.data.resData[0].quantity)
    form.setFieldValue("po",res.data.resData[0].tdsPurchaseOrder)
    if(res.data.resData[0].status === "done")
      setIsDone(true)
    if(res.data.resData[0].draftName)
      draftName.current = res.data.resData[0].draftName
  }).catch(error => {
    Noti.error({
      message: 'Error',
      description: error?.response?.data?.resultDescription
    })
  })
 }
  // const dataItemTemp = dataItem.map((r, i) => {
  //   return { ...r };
  // });
 const fncGetAddressTDS = () => {
  getAddressTDS().then(res => {
    let data = res.data.resData
    data.map(x=>x.id = x.id.toString())
    setDdlTds(data)
  }).catch(error => {
    Noti.error({
      message: 'Error',
      description: error?.response?.data?.resultDescription
    })
  })
 }

  const getRows = (rows) => [
    headerRow,
    ...rows.map((row, i) => ({
      rowId: i,
      height: HEADING_ROW_HEIGHT,
      cells: [
        {
          type: "text",
          text: row?.hbl ?? "",
          nonEditable:!editMode ? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.mbl ?? "",
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.dischargePort ?? "",
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.destination ?? "",
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.container ?? "",
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.etd ?? "",
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.etaDoor ?? "",
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "scdropdown",
          value: row?.tdsWarehouse ?? "",
          values: [
            { value: "", label: "" },
            ...ddlTds.map((x) => ({
              value: x.id,
              label: x.address
            })),
            // { value: "TDS 15065 Flight Ave", label: "TDS 15065 Flight Ave" },
            // { value: "TDS 3055 Shawnee", label: "TDS 3055 Shawnee" },
          ],
          isOpen: row?.isOpen,
          nonEditable: !editMode? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
      ],
    })),
  ];

  const getRows2 = (rows) => [
    headerRow2,
    ...rows.map((row, i) => ({
      rowId: i,
      height: HEADING_ROW_HEIGHT,
      cells: [
        {
          type: "text",
          text: row?.no ?? "",
          nonEditable:!editMode ? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.serialNo ?? "",
          nonEditable:!editMode ? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: "text",
          text: row?.shippingNo ?? "",
          nonEditable:!editMode ? true : false,
          style: editMode ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
      ],
    })),
  ];

  const rows = getRows(dataItem);

  const columns = getColumns();
  const columns2 = getColumns2();

  const handleChanges = (changes) => {
    for (let i = 0; i < changes.length; i++) {
      changes[i].newCell.text = ""+changes[i].newCell.text.replaceAll("\r", "")
    }
    setDataItem((prevData) => applyChangesToData(changes, prevData));
    dataItem.some(item => 
      Object.entries(item).some(([key, value]) => key !== 'isOpen' && value !== null && value !== "")
    ) ? setShowbtn(true) : setShowbtn(false)
  };

  const handleChanges2 = (changes, index) => {
    for (let i = 0; i < changes.length; i++) {
      changes[i].newCell.text = ""+changes[i].newCell.text.replaceAll("\r", "")
    }
    let d = applyChangesToData(changes, dataItem2[index])
    setDataItem2((prevData) => prevData.map((item, i) => i === index ? d : item));
  };

  const fncSaveDraft = (save = false) => {
    if(save) draftName.current = dName
    if(!draftName.current){
      setDName(draftName.current)
      setIsSaveOpen(true)
      return
    } 
    setSaveLoading(true)
    let payload = {
      "draftName" : draftName.current,
      "status" : "draft",
      "quantity" : form.getFieldValue("qty"),
      "tdsPurchaseOrder" : form.getFieldValue("po"),
      "detail" : []
    }

    for (let d = 0; d < dataItem.length; d++) {
        payload.detail.push({
          "container" : dataItem[d].container,
          "destination" : dataItem[d].destination,
          "dischargePort" : dataItem[d].dischargePort,
          "etaDoor" : dataItem[d].etaDoor,
          "etd" : dataItem[d].etd,
          "hbl" : dataItem[d].hbl,
          "mbl" : dataItem[d].mbl,
          "tdsWarehouse" : dataItem[d].tdsWarehouse ? parseInt(dataItem[d].tdsWarehouse) : null,
          "listSerialNo" : dataItem2[d]
        })
    }

    if(editId)
      payload.refId = editId

    postReceivedDeliveryTPV(payload).then(res => {
      setEditId(res.data.resData[0].refId)
      if(isSaveOpen) setIsSaveOpen(false)
      Noti.success({
        message: 'Success',
        description: `Your data has been saved successfully.`
      })
      setSaveLoading(false)
    }).catch(error => {
      if(isSaveOpen) setIsSaveOpen(false)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
      setSaveLoading(false)
    })
    console.log(payload)
  }

  const fncPublish = () =>{
    let allFill = dataItem.every(item =>
      Object.values(item).every(value => value !== null && value !== "")
    );
    if(!allFill){
      Noti.error({
        message: 'Error',
        description: "Please fill in all information for the entire row."
      })
      return
    }
    let allFill2 = dataItem2.every(item =>
      Object.values(item).some((value) => value.serialNo !== null && value.serialNo !== "")
    );
    if(!allFill2){
      Noti.error({
        message: 'Error',
        description: "Please fill in Serial No."
      })
      return
    }
    const totalLength = dataItem2.reduce((sum, subArray) => sum + subArray.filter(item => item.serialNo).length, 0);
    if(totalLength != form.getFieldValue("qty")){
      Noti.error({
        message: 'Error',
        description: "Please fill in the Serial No. to match the Quantity."
      })
      return
    }

    let payload = {
      "status" : "draft",
      "quantity" : form.getFieldValue("qty"),
      "tdsPurchaseOrder" : form.getFieldValue("po"),
      "detail" : [],
      "draftName" : draftName.current
    }
    for (let d = 0; d < dataItem.length; d++) {
      payload.detail.push({
        "container" : dataItem[d].container,
        "destination" : dataItem[d].destination,
        "dischargePort" : dataItem[d].dischargePort,
        "etaDoor" : dataItem[d].etaDoor,
        "etd" : dataItem[d].etd,
        "hbl" : dataItem[d].hbl,
        "mbl" : dataItem[d].mbl,
        "tdsWarehouse" : dataItem[d].tdsWarehouse ? parseInt(dataItem[d].tdsWarehouse) : null,
        "listSerialNo" : dataItem2[d]
      })
    }
    if(editId)
      payload.refId = editId

    setSaveLoading(true)
    postReceivedDeliveryTPV(payload).then(res => {
      setEditId(res.data.resData[0].refId)
      postPublishDeliveryTPV({"refId": res.data.resData[0].refId}).then(async (res2)  => {
        let mData = await res2.data.resData
        mData.totalUnit = mData.sum.length > 0 ? mData.sum.reduce((sum, arr) => sum + arr.total, 0) : 0
        setModalData(mData)
        setSaveLoading(false)
        setIsModalOpen(true)
      }).catch(error2 => {
        setSaveLoading(false)
        Noti.error({
          message: 'Error',
          description: error2?.response?.data?.resultDescription
        })
      })
    }).catch(error => {
      setSaveLoading(false)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
    })
  }

  const fncDelRow = () =>{
    if(dataItem2.length == dataItem.length)
    setDataItem2(dataItem2.slice(0, -1))
    setDataItem(dataItem.slice(0, -1))
  }

  const fncAddRow = () =>{
    setDataItem([...dataItem,{...dataItem.slice(-1)[0], ...Object.keys(dataItem.slice(-1)[0]).reduce((acc, key) => ({ ...acc, [key]: null }), {})}])
    let b = Array.from({ length: 34 }, (_, i) => ({ no: `${i + 1}`, serialNo: null, shippingNo: null }))
    setDataItem2([...dataItem2,b])
  }

  const fncUpdatePurchaseOrder = () => {
    setSaveLoading(true)
    postUpdatePurchaseOrder({"refId": editId}).then(res => {
      setSaveLoading(false)
      Noti.success({
        message: 'Success',
        description: `Your data has been saved successfully.`
      })
      
      navigate(`/megaforce-unit-delivery/`)
      setEditMode(false)
      setIsDone(true)
      setIsModalOpen(false)
    }).catch(error => {
      setSaveLoading(false)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
      setIsModalOpen(false)
    })
  }

  return (<>
    <Layout activeKey="megaforce-unit-delivery">
      <Helmet>
        <title>[NodesNow] Unit Delivery</title>
      </Helmet>
      <Breadcrumb
        itemRender={itemRender}
        items={itemMenu}
      />
      <Divider plain></Divider>
      {loadingFirst ? (
        <SkeletonLoading />
      ) : (
        <>
          <Card title={"Megaforce Unit Delivery" + (draftName.current ? " (" + draftName.current + ")" : "")} style={{ textAlign: "left" }}>
            <Form
              className="megaforce-form"
              form={form}
              onFinish={() => {
                fncPublish()
              }}
              onFinishFailed={()=> {
                Noti.error({
                  message: 'Error',
                  description: "Please fill in the form"
                })
              }}
              initialValues={{}}
              size={"default"}
              labelAlign="left"
              labelWrap
              colon={false}
              layout={"vertical"}
            >
              <div style={{display: 'flex',alignItems: 'end',justifyContent: 'space-between', gap:"20px", flexWrap:"wrap"}}>
                <div style={{display: 'flex', alignItems: 'end',gap:"20px", flexGrow: 1, flexWrap:"wrap"}}>
                <Form.Item rules={[{ required: true, message: '' }]} label="Quantity*" name="qty" style={{flexGrow: 1, width:"150px"}}>
                  <InputNumber min={1} name="qty" placeholder="Quantity" style={{width:'100%', marginTop:"15px"}} disabled={!editMode}/>
                </Form.Item>
                <Form.Item label="TDS Purchase Order Id*" name="po" rules={[{ required: true, message: '' }]} style={{flexGrow: 1, width:"150px"}}>
                  <Input
                    placeholder="TDS_POXXXXXXXX"
                    style={{ minWidth: "150px", marginTop:"15px"}}
                    name="po"
                    disabled={!editMode}
                  />
                </Form.Item>
                </div>
                <div style={{display: 'flex', alignItems: 'end',gap:"20px"}}>
                  {editMode ? <>
                    <Button
                      type="primary"
                      onClick={() => {
                        fncSaveDraft()
                      }}
                      style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                      disabled={logingApi || !showbtn}
                      className="bt-main"
                    >
                      Save Draft
                    </Button>
                    {draftName.current ? <>
                      <Button
                        type="primary"
                        onClick={() => {
                          form.submit()
                        }}
                        style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                        disabled={logingApi || !showbtn}
                        className="bt-main"
                        >
                        Publish
                      </Button>
                    </> : <></>}
                  </> : <>
                  {!isDone && <Button
                      type="primary"
                      onClick={() => {
                        setEditMode(true)
                        setShowbtn(true)
                      }}
                      style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                      className="bt-main"
                      >
                      Edit
                    </Button>}
                  </>}
                </div>
              </div>
            </Form>
            <Card
              title=""
              bordered={false}
              style={{ width: "100%", marginTop: "25px" }}
              className="table-list"
            >
              <div
               className="reactgrid-class"
                style={{
                  width: "100%",
                  overflowY: "hidden",
                  overflowX: "auto",
                }}
              >
                {!logingApi ? (
                  <ReactGrid
                    stickyTopRows={1}
                    enableRangeSelection={true}
                    customCellTemplates={{
                      scdropdown: new GridDropdownCellTemplate(),
                    }}
                    enableFillHandle={editMode}
                    onCellsChanged={handleChanges}
                    rows={rows}
                    columns={columns}
                  />
                ) : (
                  <Skeleton active />
                )}
              </div>
            </Card>
            {editMode && <div style={{width:"100%", textAlign:"right"}}>
              <PlusOutlined style={{border:"2px solid grey",margin:"20px 10px 0 0", cursor:"pointer"}} onClick={fncAddRow}/>
              {dataItem.length > 1 ? <MinusOutlined style={{border:"2px solid grey",margin:"20px 10px 0 0", cursor:"pointer"}} onClick={fncDelRow}/> : <></>}
            </div>}
            <hr style={{marginTop:"75px"}}/>
            <h4>List Serial No.*</h4>
              <Collapse size="large" style={{width:"70%"}}>
                {dataItem2.map((item, index) => (
                  <Collapse.Panel header={
                    <span>
                      {dataItem[index].hbl && (
                        <>
                          <strong>HBL:</strong> {dataItem[index].hbl}
                        </>
                      )}
                      {dataItem[index].hbl && (dataItem[index].container || dataItem[index].destination) && ', '}
                      {dataItem[index].container && (
                        <>
                          <strong>Container:</strong> {dataItem[index].container}
                        </>
                      )}
                      {dataItem[index].container && dataItem[index].destination && ', '}
                      {dataItem[index].destination && (
                        <>
                          <strong>Destination:</strong> {dataItem[index].destination}
                        </>
                      )}
                    </span>
                  }>
                    <div style={{display: "flex", width: "100%",overflow: "auto"}}>
                    <ReactGrid
                      stickyTopRows={1} 
                      enableRangeSelection={true}
                      customCellTemplates={{
                        scdropdown: new GridDropdownCellTemplate(),
                      }}
                      onCellsChanged={(e)=>{handleChanges2(e,index)}}
                      enableFillHandle={editMode}
                      rows={getRows2(item)}
                      columns={columns2}
                      />
                    </div>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </Card>
        </>
      )}
    </Layout>
      <Modal
        title='Confirmation'
        open={isModalOpen}
        centered
        width={650}
        footer={null}
        closeIcon={false}
      >
        <hr style={{borderColor:"#00000040"}}/>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
            margin:"0 20px"
          }}>
            <Row>
              <span style={{ fontSize: '18px' }}>
                The serial number data will be associated with
              </span>
            </Row>
            <Row>
              <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Purchase Order  :</span>
            </Row>
            <Row>
              <div style={{display:"flex", flexDirection:"column", width:"100%",gap:"10px"}}>
                {modalData && modalData.sum && modalData.sum.map((x)=>{
                  return(<>
                    <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                      <span style={{fontWeight:"bold"}}>{x.purchaseOrderId}</span>
                      <span style={{background:"#C1C9D2",borderRadius:"20px", padding:"5px 15px", fontWeight:"bold"}}>{x.total} Unit</span>
                    </div>
                  </>)
                })}
              </div>
            </Row>
            <hr style={{width: "100%", margin: "0 -20px", padding: "0 20px", borderColor:"#00000040"}}/>
            <Row>
              <div style={{display:"flex", flexDirection:"column", width:"100%",gap:"10px"}}>
                <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                  <span style={{fontWeight:"bold", fontSize:"20px"}}>Total:</span>
                  <span style={{fontWeight:"bold", fontSize:"20px"}}>{modalData?.totalUnit || 0} Unit</span>
                </div>
              </div>
            </Row>
            <Row style={{marginBottom: "10px"}}>
              <span>Please verify the information for accuracy before pressing the save button.</span>
            </Row>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem'
          }}>
            <Button
              type='primary'
              htmlType='submit'
              style={{width:"100px"}}
              onClick={()=>fncUpdatePurchaseOrder()}
              disabled={saveLoading}
            >Save</Button>
            <Button
              type='primary'
              style={{width:"100px"}}
              onClick={() => setIsModalOpen(false)}
              disabled={saveLoading}
            >Cancel</Button>
          </div>
      </Modal>
      <Modal
        title='Confirmation'
        open={isSaveOpen}
        centered
        width={650}
        footer={null}
        closeIcon={false}
      >
        <hr style={{borderColor:"#00000040"}}/>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
            margin:"0 20px"
          }}>
            <Row>
              <span style={{ fontSize: '18px', marginTop:"10px" }}>
                Please provide a name for your draft:
              </span>
            </Row>
            <Row>
              <Input placeholder="Draft Name" style={{ minWidth: "150px", margin:"10px 0"}} name="draftName" value={dName} onChange={(e)=>{setDName(e.target.value)}}/>
            </Row>
            <Row style={{marginBottom: "30px", fontSize: '18px'}}>
              <span>Are you sure you want to save this draft?</span>
            </Row>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem'
          }}>
            <Button
              type='primary'
              htmlType='submit'
              style={{width:"100px"}}
              onClick={()=>{fncSaveDraft(true)}}
              disabled={saveLoading || !dName}
            >Save</Button>
            <Button
              type='primary'
              style={{width:"100px"}}
              onClick={() => {draftName.current = "";setIsSaveOpen(false)}}
              disabled={saveLoading}
            >Cancel</Button>
          </div>
      </Modal>
      {saveLoading ? <>
        <Spin className='spin-loading'></Spin>
      </> : <></>}
      </>);
};

export default App;
