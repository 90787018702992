/* istanbul ignore file */
import { Link } from 'react-router-dom'

export const delay = (time) => new Promise(resolve => setTimeout(resolve, time))

export const itemRender = (route, params, items, paths) => {
  let item = route
  const last = items.map(r => r.href).indexOf(route.href) === items.length - 1
  return last ? <span>{item.title}</span> : <Link to={item.href}>{item.title}</Link>
}

export const applyChangesToData = (changes, prevData) => {
  changes.forEach((change) => {
    const itemIndex = change.rowId
    const fieldName = change.columnId
    if (change.type === 'date') {
      prevData[itemIndex][fieldName] = change.newCell.date
    } else if (change.type === 'scdropdown') {
      prevData[itemIndex][fieldName] = change.newCell.value
      prevData[itemIndex].isOpen = change.newCell.isOpen
    } else if (change.type === 'dropdown') {
      prevData[itemIndex][fieldName] = change.newCell.inputValue
      prevData[itemIndex].isOpen = change.newCell.isOpen
    } else {
      prevData[itemIndex][fieldName] = change.newCell.text
    }
  })
  return [...prevData]
}