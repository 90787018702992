import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Card, } from 'antd';
import { useAuth } from "../hooks/useAuth";
import { postLogin } from "../services/MegaforceUnitDelivery";
import Notification from "../services/Notification";
import { useAuthStore } from "../services/login";

const App = () => {
  const { login } = useAuth();
  const [loadingApi, setLoadingApi] = useState(false);
  const Noti = new Notification()
  const { setToken } = useAuthStore()

  const onFinish = (values) => {
    setLoadingApi(true)
    postLogin(values).then((res) => {
      let resData = res.data
      setLoadingApi(false)

      if (resData.resultCode === "20000") {
        // window.localStorage.setItem('user', JSON.stringify(resData.cookie));
        login(resData.token)
        setToken(resData.token)
        window.location.href = "/home";
      }else {
        Noti.error({
          message: 'Error',
          description:resData.resultDescription
        })
      }
    }).catch((error) => {
      console.log("🚀 ~ postLogin ~ error:", error)
      setLoadingApi(false)
      if(error?.response?.status == "401") {
        Noti.error({
          message: 'Error',
          description: "Incorrect Email or Password"
        })
        return
      }
      Noti.error({
        message: 'Error',
        description:error.message || ''
      })
    });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card style={{ width: 500 }}>
          <div style={{ display: "flex", justifyContent: "center",padding:'30px 0px' }}>
            <div level={2}>
              <div style={{fontSize:40,fontWeight: 900}}>  Login </div>
            </div>
          </div>
    <Form
      name="login"
      layout="vertical"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 24,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input name="email" placeholder='Email'/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        style={{margin:"20px 0"}}
      >
        <Input.Password name="password" placeholder='Input password'/>
      </Form.Item>

      <span >You can use the username and password of the axelor system to login directly.</span>

      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 24,
        }}
        style={{textAlign:"center"}}
      >
        <Button data-testid="summit" type="primary" htmlType="submit" style={{margin:"20px 0",padding:"0 50px"}} >
          Login
        </Button>


      </Form.Item>
    </Form>
    </Card>
    </div>
  );
}
export default App;
