import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  Table,
  Pagination,
  Breadcrumb,
  Divider,
  Collapse,
  Modal,
  Tooltip
} from "antd";
import { useNavigate } from 'react-router'
import { Helmet } from "react-helmet";
import PaginationShowingCount from '../component/table/PaginationShowingCount';
import PaginationChangeLimit from '../component/table/PaginationChangeLimit';
import Layout from "../component/layout/Layout";
import SkeletonLoading from '../component/SkeletonLoading';
import TableLoading from '../component/table/Loading';
import Notification from "../services/Notification";
import { itemRender } from '../utils/common'
import { getDeliveryTPV, getAddressTDS, deleteDraftDeliveryTPV } from "../services/MegaforceUnitDelivery";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const App = () => {

  const initialized = useRef(false);
  const status = useRef("done");
  const [loadingFirst, setLoadingFirst] = useState(false);
  // const [dataError, setDataError] = useState(false);

  const navigate = useNavigate()
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("10");
  // const [isStatusDone, setIsStatusDone] = useState(true);
  const [ddlTds, setDdlTds] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const Noti = new Notification()

  const itemMenu = [
    {
      href: `/megaforce-unit-delivery`,
      title: (
        <>
          <span>Megaforce Unit Delivery</span>
        </>
      )
    },
  ]
  const Columns = [
    {
      title: 'No.',
      dataIndex: 'seqNo',
      align: 'center',
      key: 'seqNo',
      sorter: false,
      width: 50,
      render: (text) => <div className="font16 px-text-center">{text || "-"}</div>
    },
    {
      title: 'Purchase Order',
      dataIndex: 'purchaseOrderId',
      key: 'purchaseOrderId',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'HBL',
      dataIndex: 'hbl',
      key: 'hbl',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Container',
      dataIndex: 'container',
      key: 'container',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Total unit',
      dataIndex: 'listSerialNo',
      key: 'listSerialNo',
      sorter: false,
      render: (text) => <span className="font16">{text?.length || "-"}</span>
    },
    {
      title: 'Ref. Unit Delivery Id',
      dataIndex: 'unitDeliveryId',
      key: 'unitDeliveryId',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'TDS Warehouse',
      dataIndex: 'tdsWarehouse',
      key: 'tdsWarehouse',
      sorter: false,
      render: (text) => <span className="font16">{text ? ddlTds.find(x => x.id == text)?.address : "-"}</span>
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: false,
      render: (data) => <span className="font16">{data ? dayjs(data).format('MM/DD/YYYY HH:mm:ss') : "-"}</span>
    },
  ]
  const Columns2 = [
    {
      title: 'HBL#',
      dataIndex: 'hbl',
      key: 'hbl',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'MBL#',
      dataIndex: 'mbl',
      key: 'mbl',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Discharge Port',
      dataIndex: 'dischargePort',
      key: 'dischargePort',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Container#',
      dataIndex: 'container',
      key: 'container',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      key: 'etd',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'ETA Door',
      dataIndex: 'etaDoor',
      key: 'etaDoor',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'TDS Warehouse',
      dataIndex: 'tdsWarehouse',
      key: 'tdsWarehouse',
      sorter: false,
      render: (text) => <span className="font16">{text ? ddlTds.find(x => x.id == text)?.address : "-"}</span>
    },
  ]

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        fncGetAddressTDS()
        setLoadingFirst(true)
        fnGetList()
      }
    },[]
  );


  const [dataList, setDataList] = useState([]);
  // const [docNameList, setDocNameList] = useState([]);
  let dataSource = status.current === "done" ? 
    dataList.map((r, i) => {
      return {
        seqNo: limit === 'All' ? i + 1 : i + 1 + ((page - 1) * limit),
        key: i,
        ...r
      }
    }) 
  : 
  dataList.map((r, i) => {
    return {
      key: i,
      ...r
    }
  })

  const fncGetAddressTDS = () => {
    getAddressTDS().then(res => {
      let data = res.data.resData
      setDdlTds(data)
    }).catch(error => {
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
    })
   }

  const onLimitChange = (e) => {
    setPage(1)
    setLimit(e)
    fnGetList(1, e)
  }

  const onPageChange = (e) => {
    setPage(e)
    fnGetList(e, limit)
  }

  const fnGetList = (page = 1, limit = 10) => {
    initialized.current = true
    let fValue = form.getFieldsValue()
    setLoadingApi(true)
    let body = {
      offset: limit === "All" ? '0' : (page - 1) * limit,
      limit: limit === 'All' ? null : limit,
      status: status.current,
    }
    if(fValue.hbl) body.hbl = fValue.hbl
    if(fValue.container) body.container = fValue.container
    if(fValue.po) body.purchaseOrderId = fValue.po
    if(fValue.tds) body.tdsWarehouse = fValue.tds
    if(fValue.do) body.draftName = fValue.do

    getDeliveryTPV(body).then((res) => {
      let resData = res.data;
      if (resData.resultCode === "20000") {
        let items = resData.resData
        items = items.map((r, i) => {
          return { ...r, key: i }
        })
        setDataList(items || [])
        setCount(resData.total || 0)
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
      }
      setLoadingFirst(false)
      setLoadingApi(false)
    }).catch((error) => {
      if(error.response?.data?.resultCode == "40401"){
        setDataList([])
        setCount(0)
        setLoadingFirst(false)
        setLoadingApi(false)
        return
      }
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setLoadingFirst(false)
      setLoadingApi(false)
    });
  }

  const onSearch = () => {
    if (!loadingApi) {
      setPage(1)
      fnGetList(1, limit)
    }
  }

  const onClear = () => {
    form.resetFields()
    setPage(1)
    setLimit("10")
    fnGetList(1, 10)
  }

  const deleteDraft = () => {
    setLoadingApi(true)
    deleteDraftDeliveryTPV(deleteId).then((res) => {
      let resData = res.data;
      if (resData.resultCode === "20000") {
        Noti.success({
          message: 'Success',
          description: `Your data has been deleted successfully.`
        })
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
      }
      setPage(1)
      fnGetList(1, limit)
      setIsModalOpen(false)
      setLoadingApi(false)
    }).catch((error) => {
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setPage(1)
      fnGetList(1, limit)
      setIsModalOpen(false)
      setLoadingApi(false)
    });
  }

  return <>
  <Layout activeKey={'megaforce-unit-delivery'} >
    <Helmet>
        <title>[NodesNow] Megaforce Unit Delivery</title>
      </Helmet>
      <Breadcrumb
        itemRender={itemRender}
        items={itemMenu}
      />
      <Divider plain></Divider>
    {loadingFirst ? <SkeletonLoading /> : <>
      {
          <Card title={
            <div className="header-insert">
              <div>Megaforce Unit Delivery</div>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/megaforce-unit-delivery/create`)
                  }}
                  style={{ minWidth: 150 }}
                  className="bt-main"
                  >
                  Insert Unit Delivery
                </Button>
            </div>
          </div>
            
            } style={{ textAlign: 'left' }} bordered={false} className='card-list'>
            <Form
              className="megaforce-form-list"
              form={form}
              onFinish={() => {
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{}}
              size={"default"}
              labelAlign="left"
              labelWrap
              colon={false}
            >
              <div style={{display: 'flex', alignItems: 'end', justifyContent: 'space-between', flexWrap:"wrap", gap:"20px"}}>
                <div style={{display: 'flex', flexWrap:"wrap", gap:"20px", flexGrow:"1"}}>
                  <div style={{display: "flex", gap: "20px", flexDirection: "column", flexGrow:"1"}}>
                    {status.current === "done" ? <>
                      <Form.Item label="Purchase Order :" name="po" >
                        <Input name="po" />
                      </Form.Item>
                    </> : <>
                      <Form.Item label="Draft Name :" name="do" >
                        <Input name="do" />
                      </Form.Item>
                    </>}
                    <Form.Item label="HBL :" name="hbl" rules={[]} >
                      <Input name="hbl" />
                    </Form.Item>
                  </div>
                  <div style={{display: "flex", gap: "20px", flexDirection: "column", flexGrow:"1"}}>
                    <Form.Item label="Container :" name="container" >
                      <Input name="container" />
                    </Form.Item>
                    <Form.Item label="TDS Warehouse :" name="tds" rules={[]} >
                      {/* <Input name="tds" /> */}
                      <Select name="tds" className="search-tds">
                        <Select.Option value="" key=""></Select.Option>
                        {
                          ddlTds.map((r ,i)=> {
                            return <Select.Option value={r.id} key={r.id}>{r.address}</Select.Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="search-clear">
                  <Button
                    type="primary"
                    onClick={() => {
                      onSearch()
                    }}
                    style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                    className="bt-main"
                  >
                    Search
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      onClear()
                    }}
                    style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                    className="bt-main"
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </Form>

            <div style={{textAlign:"left", margin:"30px 0 20px 0", borderBottom:"1px solid #F0F0F0"}}>
              <Button
                type="text"
                onClick={() => {
                  if(status.current === "done") return
                  form.resetFields()
                  status.current = "done"
                  setPage(1)
                  fnGetList(1, limit)
                  // navigate(`/megaforce-unit-delivery/create`)
                }}
                style={{ minWidth: 150, borderRadius:0, border:"1px solid #F0F0F0", background:"#FAFAFA", borderBottom:"none" }}
                className={"status-list " + (status.current === "done" ? "active" : "")}
                >
                Done
              </Button>
              <Button
                type="text"
                onClick={() => {
                  if(status.current === "draft") return
                  form.resetFields()
                  status.current = "draft"
                  setPage(1)
                  fnGetList(1, limit)
                  // navigate(`/megaforce-unit-delivery/create`)
                }}
                style={{ minWidth: 150, borderRadius:0, border:"1px solid #F0F0F0", background:"#FAFAFA", borderBottom:"none" }}
                className={"status-list " + (status.current === "draft" ? "active" : "")}
                >
                Draft
              </Button>
            </div>
            {status.current === "done" ? <>
              {loadingApi ? <SkeletonLoading /> : <>
                {dataList.length > 0 ? <>
                  <Table
                    key={'1'}
                    rowKey={record => record.key}
                    bordered
                    showSorterTooltip={false}
                    scroll={{ x: 300 }} 
                    columns={[
                      ...Columns,
                      {
                        title: '',
                        dataIndex: '',
                        align: 'left',
                        key: '',
                        sorter: false,
                        width: 100,
                        render: (data) => <div>
                          <span style={{cursor:"pointer",textDecoration:"underline",color:"#116BEA"}}
                          onClick={()=>{navigate(`/megaforce-unit-delivery/view/${data._id}`)}}>
                            View
                          </span>
                        </div>,
                      }
                    ]
                    }
                    loading={{
                      spinning: loadingApi,
                      indicator: <TableLoading />
                    }}
                    locale={{ emptyText: <div> No Data </div> }}
                    pagination={false}
                    dataSource={dataSource}
                    size="small"
                    footer={() => <>
                      <Row gutter={16}>
                        <Col className="" span={12}>
                          <PaginationChangeLimit onChange={(e) => {
                            onLimitChange(e)
                          }} limit={limit} loading={loadingApi} />
                          <PaginationShowingCount limit={limit} count={count} page={page} />
                        </Col>
                        <Col className="text-right" span={12} style={{ textAlign: 'right' }}>
                          {
                            limit === 'All' ? <></> :
                              <Pagination
                                simple
                                current={page}
                                total={count}
                                pageSize={limit}
                                loading={loadingApi}
                                onChange={(page, pageSize) => {
                                  onPageChange(page);
                                }} />
                          }
                        </Col>
                      </Row>
                    </>}
                  />
                </> : <>
                  <div className="no-data">No Data Found</div>
                </>}
              </>}
            </> : status.current === "draft" ? <>
              {loadingApi ? <SkeletonLoading /> : <>
                {dataList.length > 0 ? <>
                  <div>
                      <Collapse size="large" style={{width:"100%",marginBottom:"10px"}}>
                        {dataList.map((r ,i)=> (
                          <Collapse.Panel key={r._id} header={
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                              <span>
                                {r.draftName && <><strong>Draft name:</strong> {r.draftName}</>}
                                {r.draftName && r.created_at && ', '}
                                {r.created_at && <><strong>Updated At:</strong> {dayjs(r.updated_at).format('MM/DD/YYYY HH:mm:ss')}</>}
                              </span>
                              <div className="draft-list-control" style={{minWidth:"60px"}}>
                                <span className="btn-control">
                                  <Tooltip title="Edit" overlayClassName="tooltip-icon">
                                    <EditOutlined style={{margin:"0 5px"}} onClick={()=>{navigate(`/megaforce-unit-delivery/${r._id}`)}}/>
                                  </Tooltip>
                                </span>
                                <span className="btn-control">
                                  <Tooltip title="Delete" overlayClassName="tooltip-icon">
                                    <DeleteOutlined style={{margin:"0 5px"}} onClick={(e)=>{e.stopPropagation();setIsModalOpen(true);setDeleteId(r._id)}}/>
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                            }>
                            <Table
                              key={'1'}
                              rowKey={record => record.key}
                              bordered
                              showSorterTooltip={false}
                              scroll={{ x: 300 }} 
                              columns={[
                                ...Columns2
                              ]
                              }
                              loading={{
                                spinning: loadingApi,
                                indicator: <TableLoading />
                              }}
                              locale={{ emptyText: <div> No Data </div> }}
                              pagination={false}
                              dataSource={dataSource[i].detail}
                              size="small"
                            />
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    <Row gutter={16}>
                      <Col className="" span={12}>
                        <PaginationChangeLimit onChange={(e) => {
                          onLimitChange(e)
                        }} limit={limit} loading={loadingApi} />
                        <PaginationShowingCount limit={limit} count={count} page={page} />
                      </Col>
                      <Col className="text-right" span={12} style={{ textAlign: 'right' }}>
                        {
                          limit === 'All' ? <></> :
                            <Pagination
                              simple
                              current={page}
                              total={count}
                              pageSize={limit}
                              loading={loadingApi}
                              onChange={(page, pageSize) => {
                                onPageChange(page);
                              }} />
                        }
                      </Col>
                    </Row>
                  </div>
                </> : <>
                  <div className="no-data">No Data Found</div>
                </>}
              </>}
            </> : <></>}
          </Card>
      }
    </>
    }
  </Layout>
  <Modal
        title='Confirmation'
        open={isModalOpen}
        centered
        width={550}
        footer={null}
        closeIcon={false}
      >
        <hr style={{borderColor:"#00000040"}}/>
          
            <Row>
              <span style={{ fontSize: '18px', margin:"10px 0 50px 0" }}>
              Are you sure you want to delete this draft?
              </span>
            </Row>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem'
          }}>
            <Button
              type='danger'
              htmlType='submit'
              style={{width:"120px"}}
              onClick={()=>{deleteDraft()}}
              // disabled={saveLoading}
            >Yes, Delete</Button>
            <Button
              type='primary'
              style={{width:"120px"}}
              onClick={() => {setDeleteId(null);setIsModalOpen(false)}}
              // disabled={saveLoading}
            >Cancel</Button>
          </div>
  </Modal>
  </>;
};

export default App;
