/* istanbul ignore file */
import { put, get } from './RequestApi'

export function getMaterialAcquisition(params) {
  return get({ url: `/stock/rma-warehouse/replacement`, params })
}

export function putMaterialAcquisition(data) {
  return put({ url: `/stock/rma-warehouse/replacement/update`, data })
}

export function getRma(params) {
  return get({ url: `/stock/rma-warehouse/device`, params })
}

export function putRma(data) {
  return put({ url: `/stock/rma-warehouse/device/update`, data })
}