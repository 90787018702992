/* istanbul ignore file */
import React from "react";
import {
  Select,
} from "antd";
const PaginationChangeLimit = (props) => {
  let option = props.option || ['10','20','50','All'];
  let disabled = props.disabled || false;
  let loading = props.loading || false;
  let limit = props.limit || 0

  return (<Select size={'small'} suffixIcon={<div size={16} />} value={limit} className="list-limit"
                       style={{ width: 80, marginRight: 9, fontSize: 14, textAlign:"center" }} onChange={(e) => {
                         if(props.onChange){
                           props.onChange(e)
                         }
                       }} loading={loading}
                          disabled={disabled}>
                         {
                           option.map((r ,i)=> {
                             return <Select.Option className="list-limit-option" value={r} key={i}>{r}</Select.Option>
                           })
                         }
</Select>);
};

export default PaginationChangeLimit;
