import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Card,
  Button,
  Collapse,
  Breadcrumb,
  Divider,
  Checkbox,
  Upload,
  Image as AntdImage,
  message,
  Spin
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router'
import { Helmet } from "react-helmet";
import Layout from "../component/layout/Layout";
import SkeletonLoading from '../component/SkeletonLoading';
import Notification from "../services/Notification";
import { itemRender } from '../utils/common'
import { getRma, putRma } from "../services/ReturnMerchandiseAuthorization";
import { useAuth } from "../hooks/useAuth";


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const App = () => {
  
  const initialized = useRef(false);
  const { decodedUser } = useAuth();
  const [loadingFirst, setLoadingFirst] = useState(false);
  const navigate = useNavigate()
  const [loadingApi, setLoadingApi] = useState(false);
  const [form] = Form.useForm();
  const Noti = new Notification()
  const [fileList, setFileList] = useState([])
  const [rmaList, setRmaList] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [serialNumberList, setSerialNumberList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [isView, setIsView] = useState(false);
  
  const { id } = useParams()
  // const [dataError, setDataError] = useState(false);

  // const [isStatusDone, setIsStatusDone] = useState(true);

  const itemMenu = [
    {
      href: `/return-merchandise-authorization`,
      title: (
        <>
          <span>Return Merchandise Authorization</span>
        </>
      )
    },
    {
      title: (
        <>
          <span>Received unit to RMA Warehouse</span>
        </>
      )
    },
  ]

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        setLoadingFirst(true)
        fnGetDetail()
      }
    },[]
  );

  const fnGetDetail = () =>{
    setLoadingApi(true)
    let body = {
      rmaId: id,
      offset: 0,
      limit: 1
    }
    getRma(body).then((res) => {
      let resData = res.data;
      if (resData.resultCode === "20000") {
        let items = resData.resData[0] || {}
        form.setFieldsValue(items)
        let i = items.serialNumberList.map((x)=>{
          return {
            ...x,
            isCheck : x.isReceived ? true : false,
            receivedBy : x.isReceived ? x.receivedBy ? x.receivedBy : x.receivedBy : decodedUser.name,
            imageRMA : x.imageRMA.length > 0 ? x.imageRMA.map((y)=>y.replaceAll("188.166.254.121:10501","axelor-nodesnow-dev.scapp.work")) : [],
            imageUnit : x.imageUnit.length > 0 ? x.imageUnit.map((y)=>y.replaceAll("188.166.254.121:10501","axelor-nodesnow-dev.scapp.work")) : []
          }
        })
        if(items.status == 3) setIsView(true)
        setSerialNumberList(i)
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
      }
      setLoadingFirst(false)
      setLoadingApi(false)
    }).catch((error) => {
      setLoadingFirst(false)
      setLoadingApi(false)
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      navigate(`/return-merchandise-authorization/`)
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handlePreviewView = async (file) => {
    setPreviewImage(file);
    setPreviewOpen(true);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = (e, index) => {
    setFileList((prevFileList) => {
      const updatedFileList = { ...prevFileList };
      if (!updatedFileList[index]) {
        updatedFileList[index] = [];
      }

      if (e.file.status === 'uploading') {
        updatedFileList[index] = [...updatedFileList[index], e.file];
      } else if (e.file.status === 'done') {
        const fileIndex = updatedFileList[index].findIndex(
          (file) => file.uid === e.file.uid
        );
        if (fileIndex !== -1) {
          updatedFileList[index][fileIndex] = { ...e.file, status: 'done' };
        } else {
          updatedFileList[index] = [...updatedFileList[index], { ...e.file, status: 'done' }];
        }
      } else if (e.file.status === 'removed') {
        const fileIndex = updatedFileList[index].findIndex(
          (file) => file.uid === e.file.uid
        );
        if (fileIndex !== -1) {
          updatedFileList[index].splice(fileIndex, 1);
        }
      }
      setTimeout(()=>{
        setFileList(fileList);
      },10)
      return updatedFileList;
    });
  };

  const handleChange2 = (e, index) => {
    setRmaList((prevFileList) => {
      const updatedFileList = { ...prevFileList };
      if (!updatedFileList[index]) {
        updatedFileList[index] = [];
      }

      if (e.file.status === 'uploading') {
        updatedFileList[index] = [...updatedFileList[index], e.file];
      } else if (e.file.status === 'done') {
        const fileIndex = updatedFileList[index].findIndex(
          (file) => file.uid === e.file.uid
        );
        if (fileIndex !== -1) {
          updatedFileList[index][fileIndex] = { ...e.file, status: 'done' };
        } else {
          updatedFileList[index] = [...updatedFileList[index], { ...e.file, status: 'done' }];
        }
      } else if (e.file.status === 'removed') {
        const fileIndex = updatedFileList[index].findIndex(
          (file) => file.uid === e.file.uid
        );
        if (fileIndex !== -1) {
          updatedFileList[index].splice(fileIndex, 1);
        }
      }
      setTimeout(()=>{
        setRmaList(rmaList);
      },10)
      return updatedFileList;
    });
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 1920;
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
  
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob((blob) => {
            if (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
                uid: file.name
              });
              resolve(compressedFile);
            } else {
              reject(new Error('Compression error'));
            }
          }, file.type, 0.8);
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
    });
  };
  
  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    let compressedFile = file
    if(file.size / 1024 / 1024 > 2){
      compressedFile = await compressImage(file);
    }
    const isLt2M = compressedFile.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error('Image size is too large');
    }
    return isJpgOrPng && isLt2M;
  };

  const saveSubmit = async () => {
    setLoadingApi(true);
    let payload = {
      rmaId: id,
      rmaNo: form.getFieldValue("rmaNo"),
      serialNumberlist: []
    };
  
    for (const [index, serialItem] of serialNumberList.entries()) {
      if (serialItem.isCheck != true || serialItem.isReceived) continue;
  
      let rmaArr = [];
      let fileArr = [];
  
      if (rmaList[index] && rmaList[index].length > 0) {
        for (const file of rmaList[index]) {
          if (file.size / 1024 / 1024 > 2) {
            file.originFileObj = await compressImage(file.originFileObj);
          }
          rmaArr.push({
            name: file.name,
            type: file.type,
            url: await getBase64(file.originFileObj),
          });
        }
      }
  
      if (fileList[index] && fileList[index].length > 0) {
        for (const file of fileList[index]) {
          if (file.size / 1024 / 1024 > 2) {
            file.originFileObj = await compressImage(file.originFileObj);
          }
          fileArr.push({
            name: file.name,
            type: file.type,
            url: await getBase64(file.originFileObj),
          });
        }
      }
  
      payload.serialNumberlist.push({
        serialNumber: serialItem.serialNumber,
        additionalInfo: serialItem.additionalInformation,
        receivedBy: serialItem.receivedBy,
        imageRMA: rmaArr,
        imageUnit: fileArr,
        isReceived: serialItem.isCheck
      });
    }
  
    if (payload.serialNumberlist.length == 0) {
      Noti.warning({
        message: 'Please check at least 1 of the unit',
        description: ''
      });
      setLoadingApi(false);
      return;
    }
  
    try {
      const res = await putRma(payload);
      let resData = res.data;
      if (resData.resultCode === "20000") {
        Noti.success({
          message: 'Success',
          description: `Your data has been saved successfully.`
        })
        fnGetDetail();
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        });
        setLoadingApi(false);
      }
    } catch (error) {
      setLoadingApi(false);
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      });
      navigate(`/return-merchandise-authorization/`);
    }
  };
  
  return <>
  <Layout activeKey={'rma'} >
    <Helmet>
      <title>[NodesNow] RMA</title>
    </Helmet>
    <Breadcrumb itemRender={itemRender} items={itemMenu}/>
    <Divider plain></Divider>
    {loadingFirst ? <SkeletonLoading /> : <>
      {
        <Card title={"Received unit to RMA Warehouse"} style={{ textAlign: 'left' }} bordered={false} className='card-list'>
          <Form
            form={form}
            onFinish={() => {
            }}
            onFinishFailed={()=> {
              Noti.error({
                message: 'Error',
                description: "Please fill in the form"
              })
            }}
            initialValues={{}}
            size={"default"}
            labelAlign="left"
            labelWrap
            colon={false}
            layout={"vertical"}
          >
            <div className="rma-detail-container">
              <div style={{display: 'flex', alignItems: 'end',justifyContent: 'space-between', flexWrap:"wrap", flex:1}}>
                <div style={{display: 'flex',  width:"100%", gap:"20px", flexWrap:"wrap"}}>
                  <Form.Item label="RMA No." name="rmaNo"  style={{flexGrow: 1, width:"150px"}}>
                    <Input
                      placeholder=""
                      style={{ minWidth: "150px"}}
                      name="rmaNo"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="MAT No." name="matNo" style={{flexGrow: 1, width:"150px"}}>
                    <Input
                      placeholder=""
                      style={{ minWidth: "150px"}}
                      name="matNo"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Quantity" name="qty" style={{flexGrow: 1, width:"150px"}}>
                    <Input
                      placeholder=""
                      style={{ minWidth: "150px"}}
                      name="qty"
                      disabled
                    />
                  </Form.Item>
                </div>
                <div style={{display: 'flex', width:"100%", gap:"20px"}}>
                </div>
              </div>
              <div>
                {isView ? <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/return-merchandise-authorization/`)
                  }}
                  style={{ marginLeft: 4, minWidth: 150, marginTop: 25 }}
                  className="bt-main"
                  >
                  Back
                </Button> : <Button
                  type="primary"
                  onClick={() => {
                    saveSubmit()
                  }}
                  style={{ marginLeft: 4, minWidth: 150, marginTop: 25 }}
                  className="bt-main"
                  disabled={loadingApi}
                  >
                  Save
                </Button>}
              </div>
            </div>
          </Form>
          <div style={{margin:"20px 0 10px 0"}}>Received unit to RMA Warehouse</div>
          <div>
            <Collapse size="large" expandIconPosition={"end"} style={{width:"90%",marginBottom:"10px"}}>
              {serialNumberList.map((r ,i)=> (
                <Collapse.Panel key={r._id} header={
                  <div>
                    <Checkbox checked={r.isCheck} disabled={r.isReceived == true || isView || loadingApi} onClick={(e) =>{
                      e.stopPropagation();
                      return
                    }} onChange={(e)=>{
                      if(!fileList[i] || (fileList[i] && fileList[i].length == 0) || !rmaList[i] || (rmaList[i] && rmaList[i].length == 0)){
                        e.preventDefault()
                        e.stopPropagation();
                        Noti.warning({
                          message: 'Please upload the photo',
                          description: ''
                        })
                        return
                      }
                      console.log(e.target.checked)
                      serialNumberList[i].isCheck = e.target.checked;
                      setSerialNumberList([...serialNumberList]);
                      }}></Checkbox>
                    <span style={{marginLeft:"5px"}}>
                    S/N : {r.serialNumber}, {r.productName} 
                    </span>
                  </div>
                  }>
                  <div>
                    <span style={{marginRight:"99px"}}>Description</span>
                    <Input style={{maxWidth:"500px"}} value={r.description} disabled/>
                  </div>
                  <div style={{marginTop:"10px"}}>
                    <span style={{marginRight:"10px"}}>Additional Information</span>
                    <Input style={{maxWidth:"500px"}} value={r.additionalInformation} disabled={r.isCheck == true || r.isReceived == true || isView || loadingApi} onChange={(e)=> {
                      serialNumberList[i].additionalInformation = e.target.value;
                      setSerialNumberList([...serialNumberList]);
                    }
                    }/>
                  </div>
                  <div style={{marginTop:"10px"}}>
                    <span style={{marginRight:"95px"}}>Received By</span>
                    <Input style={{maxWidth:"500px"}} value={r.receivedBy} disabled={r.isCheck == true || r.isReceived == true || isView || loadingApi} onChange={(e)=> {
                      serialNumberList[i].receivedBy = e.target.value;
                      setSerialNumberList([...serialNumberList]);
                    }
                    }/>
                  </div>
                  <div style={{marginTop:"10px"}}>
                    {r.isReceived || isView ? 
                      <span>Photo list of unit with their serial number</span>
                    :
                      <span>Take photo of unit with their serial number <span style={{color:"red"}}>*</span></span>
                    }
                    {r.isReceived || isView ? <>
                      <div style={{display:"flex", gap:"10px", flexWrap:"wrap"}}>
                      {r.imageUnit.map((x)=><>
                        <img className="img-zoom" onClick={()=>handlePreviewView(x)} height={150} width={150} src={x}></img>
                       </>
                      )}
                      </div>
                    </> : <Upload
                      customRequest={dummyRequest}
                      beforeUpload={beforeUpload}
                      listType="picture-card"
                      fileList={fileList[i] || []}
                      onPreview={handlePreview}
                      onChange={(e)=>handleChange(e,i)}
                      multiple
                      disabled={r.isCheck == true || r.isReceived == true || isView || loadingApi}
                    >
                    <button style={{border: 0, background: 'none',}} type="button">
                      <PlusOutlined />
                      <div style={{marginTop: 8}}>upload</div>
                    </button>
                    </Upload>}
                    {previewImage && (
                      <AntdImage
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                      />
                    )}
                  </div>
                  <div style={{marginTop:"10px"}}>
                    {r.isReceived || isView ? 
                      <span>Photo list of RMA document with Warehouse staff signature</span>
                    :
                      <span>Take photo of RMA document with Warehouse staff signature <span style={{color:"red"}}>*</span></span>
                    }
                    {r.isReceived || isView ? <>
                      <div style={{display:"flex", gap:"10px", flexWrap:"wrap"}}>
                      {r.imageRMA.map((x)=>
                        <img className="img-zoom" onClick={()=>handlePreviewView(x)} height={150} width={150} src={x}></img>
                      )}
                      </div>
                    </> :<Upload
                      customRequest={dummyRequest}
                      beforeUpload={beforeUpload}
                      listType="picture-card"
                      fileList={rmaList[i] || []}
                      onPreview={handlePreview}
                      onChange={(e)=>handleChange2(e,i)}
                      multiple
                      disabled={r.isCheck == true || r.isReceived == true || isView || loadingApi}
                    >
                    <button style={{border: 0, background: 'none',}} type="button">
                      <PlusOutlined />
                      <div style={{marginTop: 8}}>upload</div>
                    </button>
                    </Upload>}
                  </div>
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Card>
      }
    </>
    }
  </Layout>
  {loadingApi && !loadingFirst ? <>
    <Spin className='spin-loading'></Spin>
  </> : <></>}
  </>;
};

export default App;
