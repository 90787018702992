import './App.css'
import MegaforceUnitDelivery from './Pages/megaforce-unit-delivery'
import MegaforceUnitDeliveryList from './Pages/megaforce-unit-delivery-list'
import MegaforceUnitDeliveryDone from './Pages/megaforce-unit-delivery-done'
import ReturnMerchandiseAuthorizationList from './Pages/return-merchandise-authorization-list'
import ReturnMerchandiseAuthorizationDetail from './Pages/return-merchandise-authorization-detail'
import RmaList from './Pages/rma-list'
import RmaDetail from './Pages/rma-detail'
import AuthLayout from './component/layout/AuthLayout';
import HomeLayout from './component/layout/HomeLayout';
import Home from './Pages/home';
import ProtectedLayout from './component/layout/ProtectedLayout';
import ProtectedRoute from './component/layout/ProtectedRoute';
import LoginPage from './Pages/login'
import { ConfigProvider } from 'antd'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  defer,
  Navigate
} from "react-router-dom"
import { getStorage } from './hooks/useLocalStorage';

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = getStorage("token")
      resolve(user);
    }, 10)
  );

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<HomeLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path='/' element={ <Navigate to="/login" /> }/>
      </Route>

      <Route path="/" element={<ProtectedLayout />}>
        {/* <Route path="/return-merchandise-authorization" element={<ProtectedRoute element={<ReturnMerchandiseAuthorizationList />} allowedGroups='RMA Warehouse' />} />
        <Route path="/return-merchandise-authorization/view/:id" element={<ProtectedRoute element={<ReturnMerchandiseAuthorizationDetail />} allowedGroups='RMA Warehouse' />} /> */}
        <Route path="/return-merchandise-authorization" element={<ProtectedRoute element={<RmaList />} allowedGroups='RMA Warehouse' />} />
        <Route path="/return-merchandise-authorization/view/:id" element={<ProtectedRoute element={<RmaDetail />} allowedGroups='RMA Warehouse' />} />

        <Route path="/megaforce-unit-delivery" element={<ProtectedRoute element={<MegaforceUnitDeliveryList />}/>} />
        <Route path="/megaforce-unit-delivery/create" element={<ProtectedRoute element={<MegaforceUnitDelivery />}/>} />
        <Route path="/megaforce-unit-delivery/:id" element={<ProtectedRoute element={<MegaforceUnitDelivery />}/>} />
        <Route path="/megaforce-unit-delivery/view/:id" element={<ProtectedRoute element={<MegaforceUnitDeliveryDone />}/>} />
        
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<div />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Route>
    </Route>
  )
)

const App = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            statusSize: 12,
          },
          Button: {
            colorPrimary: '#313131',
          },
          Steps: {
            colorPrimary: '#F28626',
            lineWidth: 2,
            fontSizeSM: 12,
            fontWeightStrong: 700,
            colorFillContent: '#C8C8CD',
            margin: 1,
            paddingSM: 12
          },
          Form: {
            labelFontSize: 12,
            labelHeight: 20,
            labelColonMarginInlineStart: 2,
            labelColonMarginInlineEnd: 2,
            itemMarginBottom: 20,
            labelRequiredMarkColor: '#D1333D',

          },
          Input: {
            paddingBlock: 10,
            paddingInline: 12
          },
          InputNumber: {
            paddingBlock: 10,
            paddingInline: 12
          },
          Select: {
            paddingBlock: 10,
            paddingInline: 12
          },
          Checkbox: {
            borderRadiusSM: 2,
            paddingXS: 8
          },
          Switch: {
            colorPrimary: '#F28626',
            colorPrimaryHover: '#F28626',
          }
        },
        token: {
          colorBorderBg: '#F28626',
          colorBorderSecondary: '#C8C8CD',
          paddingXS: 2,
          fontSizeIcon: 16,
          colorPrimary: '#313131',
          colorText: '#17171A',
          borderRadius: 8,
          controlOutlineWidth: 1,
          fontFamily: "Open Sans",
          controlHeight: 44,
          fontSize: 16,
          colorBgContainer: '#fff',
          colorError: '#D1333D',
          paddingSM: 24,
          colorBgContainerDisabled: '#F3F5F8',
          colorTextDisabled: '#ABB3BE'
        },
      }}
    >
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  )
}

export default App