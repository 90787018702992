/* istanbul ignore file */
import React from "react";

const PaginationShowingCount = (props) => {
  let limit = props.limit || 10;
  let count = props.count || 0;
  let page = props.page || 1;
  return (<span style={{ fontSize: '14px' }}>
  Showing {limit === 'All' ? (count === 0 ? 0 : 1) : count === 0 ? 0 : (1 + ((page -1) * limit))} to {limit === 'All' ? count : ((count < 1 + ((page) * limit)) ? count : ( ((page) * limit)) )  } of {count} entries
  </span>);
};

export default PaginationShowingCount;
