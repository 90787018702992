import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
// import LinearProgress from "@mui/material/LinearProgress";
// import Alert from "@mui/material/Alert";
import { AuthProvider } from "../../hooks/useAuth";

const AuthLayout = () => {
  const outlet = useOutlet();
  const { userPromise } = useLoaderData();

  return (
    <Suspense fallback={<div />}>
      <Await
        resolve={userPromise}
        errorElement={<div severity="error">Something went wrong!</div>}
        children={(user) => (<>
          <AuthProvider userData={user}>{outlet}</AuthProvider>
        </>)}
      />
    </Suspense>
  );
};


export default AuthLayout;
