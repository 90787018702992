import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
// import { AppBar } from "./AppBar";

const ProtectedLayout = ({ element, allowedGroups = "" }) => {
  const { decodedUser } = useAuth();
  if (!decodedUser) return <Navigate to="/login" />;
  if (decodedUser?.group.toLowerCase() == "rma warehouse") {
    if(allowedGroups.toLowerCase() == "rma warehouse"){
      return element;
    }else{
      return <Navigate to="/home" replace />;
    }
  }
  return element;
};

export default ProtectedLayout;
