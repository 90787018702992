import { create } from 'zustand';
import { jwtDecode } from 'jwt-decode';
const defaultToken = false;
export const useAuthStore = create()((set, get) => {
  return {
    token: defaultToken,
    setToken: (token) => {
      // set cookie
      const jwt = token;
      const decodedToken = jwt ? jwtDecode(jwt) : null;
      if(!decodedToken) return null
      let expire = new Date(decodedToken.exp * 1000)
      document.cookie = "token=" + token + ";path=/; expires="+ expire.toUTCString();
      set(() => {
        return { token: token };
      });
    },
    getToken: () => {
      return  get().token
    },
  };
});