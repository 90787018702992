import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  Table,
  Pagination,
  Breadcrumb,
  Divider,
} from "antd";
import { useNavigate } from 'react-router'
import { Helmet } from "react-helmet";
import Layout from "../component/layout/Layout";
import SkeletonLoading from '../component/SkeletonLoading';
import TableLoading from '../component/table/Loading';
import Notification from "../services/Notification";
import { itemRender } from '../utils/common'
import { getMaterialAcquisition } from "../services/ReturnMerchandiseAuthorization";
import PaginationShowingCount from '../component/table/PaginationShowingCount';
import PaginationChangeLimit from '../component/table/PaginationChangeLimit';
import dayjs from "dayjs"

const App = () => {

  const initialized = useRef(false);
  const [count, setCount] = useState(12);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [loadingFirst, setLoadingFirst] = useState(false);
  const navigate = useNavigate()
  const [loadingApi, setLoadingApi] = useState(false);
  const [form] = Form.useForm();
  const Noti = new Notification()
  const [dataList, setDataList] = useState([]);
  let dataSource = dataList || []
  let statusObj = {
    5: "REP_Waiting deliver to WH",
    7: "REP_Replacement Unit Completed"
  }


  const itemMenu = [
    {
      href: `/return-merchandise-authorization`,
      title: (
        <>
          <span>Return Merchandise Authorization</span>
        </>
      )
    },
  ]
  const Columns = [
    {
      title: 'RMA No. ',
      dataIndex: 'rmaNo',
      key: 'rmaNo',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'MAT No.',
      dataIndex: 'matNo',
      key: 'matNo',
      sorter: false,
      render: (text) => <div className="font16">{text || "-"}</div>
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      align: 'center',
      key: 'qty',
      sorter: false,
      render: (text) => <span className="font16">{text || "0"}</span>
    },
    {
      title: 'Document Date',
      dataIndex: 'documentDate',
      key: 'documentDate',
      sorter: false,
      render: (data) => <span className="font16">{data ? dayjs(data).format('MMM-DD, YYYY') : null}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      render: (text) => <span className="font16">{ text ? statusObj[text] ? statusObj[text] : text : "-"}</span>
    },
  ]

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        setLoadingFirst(true)
        fnGetList()
      }
    },[]
  );

  const onLimitChange = (e) => {
    setPage(1)
    setLimit(e)
    fnGetList(1, e)
  }

  const onPageChange = (e) => {
    setPage(e)
    fnGetList(e, limit)
  }

  const fnGetList = (page = 1, limit = 10) => {
    let fValue = form.getFieldsValue()
    setLoadingApi(true)
    let body = {
      offset: limit === "All" ? '0' : (page - 1) * limit,
      limit: limit === 'All' ? null : limit,
    }
    if(fValue.rmaNo) body.rmaNo = fValue.rmaNo
    if(fValue.matNo) body.matNo = fValue.matNo

    getMaterialAcquisition(body).then((res) => {
      let resData = res.data;
      if (resData.resultCode === "20000") {
        let items = resData.resData
        items = items.map((r, i) => {
          return { ...r, key: i }
        })
        setDataList(items || [])
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
        setDataList([])
      }
      setCount(resData?.total || 0)
      setLoadingFirst(false)
      setLoadingApi(false)
    }).catch((error) => {
      setDataList([])
      setCount(0)
      setLoadingFirst(false)
      setLoadingApi(false)
      if(error.response?.data?.resultCode == "40401"){
        return
      }
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
    });
  }

  return <>
  <Layout activeKey={'return-merchandise-authorization'} >
    <Helmet>
      <title>[NodesNow] RMA</title>
    </Helmet>
    <Breadcrumb itemRender={itemRender} items={itemMenu}/>
    <Divider plain></Divider>
    {loadingFirst ? <SkeletonLoading /> : <>
      {
        <Card title={"Return Merchandise Authorization (RMA)"} style={{ textAlign: 'left' }} bordered={false} className='card-list'>
          <Form
            form={form}
            onFinish={() => {
              setPage(1)
              fnGetList(1, limit)
            }}
            onFinishFailed={()=> {
              Noti.error({
                message: 'Error',
                description: "Please fill in the form"
              })
            }}
            initialValues={{}}
            size={"default"}
            labelAlign="left"
            labelWrap
            colon={false}
            layout={"vertical"}
          >
            <div style={{display: 'flex',alignItems: 'end',justifyContent: 'space-between', gap:"20px", flexWrap:"wrap"}}>
              <div style={{display: 'flex', alignItems: 'end',gap:"20px", flexGrow: 10, flexWrap:"wrap"}}>
              <Form.Item label="RMA No." name="rmaNo" style={{flexGrow: 1, width:"150px"}}>
                <Input
                  placeholder=""
                  style={{ minWidth: "150px", marginTop:"15px"}}
                  name="rmaNo"
                />
              </Form.Item>
              <Form.Item label="MAT No." name="matNo"  style={{flexGrow: 1, width:"150px"}}>
                <Input
                  placeholder=""
                  style={{ minWidth: "150px", marginTop:"15px"}}
                  name="matNo"
                />
              </Form.Item>
              </div>
              <div className="search-clear-rma">
                  <Button
                    type="primary"
                    onClick={() => {
                      form.submit()
                    }}
                    style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                    className="bt-main"
                  >
                    Search
                  </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        form.resetFields()
                        form.submit()
                      }}
                      style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                      className="bt-main"
                      >
                      Clear
                    </Button>
              </div>
            </div>
          </Form>

          {dataSource.length > 0 ? <>
            <div style={{marginTop:"75px"}}>
              <Table
                key={'1'}
                rowKey={record => record.key}
                bordered
                showSorterTooltip={false}
                scroll={{ x: 300 }} 
                columns={[
                  ...Columns,
                  {
                    title: 'Action',
                    dataIndex: '',
                    align: 'left',
                    key: '',
                    sorter: false,
                    width: 250,
                    render: (data) => <div>
                      <span style={{cursor:"pointer",textDecoration:"underline",color:"#116BEA"}}
                      onClick={()=>{navigate(`/return-merchandise-authorization/view/${data.rmaNo}`)}}>
                        {data.status == 5 ? "Received defect unit to RMA WH" : data.status == 7 ? "View" : ""}
                      </span>
                    </div>
                  }
                ]
                }
                loading={{
                  spinning: loadingApi,
                  indicator: <TableLoading />
                }}
                locale={{ emptyText: <div> No Data </div> }}
                pagination={false}
                dataSource={dataSource}
                size="small"
                footer={() => <>
                  <Row gutter={16}>
                    <Col className="" span={12}>
                      <PaginationChangeLimit onChange={(e) => {
                        onLimitChange(e)
                      }} limit={limit} loading={loadingApi} />
                      <PaginationShowingCount limit={limit} count={count} page={page} />
                    </Col>
                    <Col className="text-right" span={12} style={{ textAlign: 'right' }}>
                      {
                        limit === 'All' ? <></> :
                          <Pagination
                            simple
                            current={page}
                            total={count}
                            pageSize={limit}
                            loading={loadingApi}
                            onChange={(page, pageSize) => {
                              onPageChange(page);
                            }} />
                      }
                    </Col>
                  </Row>
                </>}
              />
            </div>
          </> : <>
            <div className="no-data" style={{marginTop:"50px"}}>No Data Found</div>
          </>}
        </Card>
      }
    </>
    }
  </Layout>
  </>;
};

export default App;
