/* istanbul ignore file */
import { post, put, get, deleteApi } from './RequestApi'

export function getAddressTDS(params) {
  return get({ url: `/tpv/address`, params })
}

export function getDeliveryTPV(params) {
  return get({ url: `/tpv/purchases-order/delivery`, params })
}

export function postReceivedDeliveryTPV(data) {
  return post({ url: `/tpv/purchases-order/delivery/unit`, data })
}

export function postPublishDeliveryTPV(data) {
  return post({ url: `/tpv/purchases-order/delivery/publish`, data })
}

export function postUpdatePurchaseOrder(data) {
  return post({ url: `/tpv/purchases-order/delivery`, data })
}

export function deleteDraftDeliveryTPV(data) {
  return deleteApi({ url: `/tpv/purchases-order/delivery/unit/${data}` })
}

export function postLogin(body) {
  return post({ url: `/axelor/login`, data : body});
}
// export function a(data, id) {
//   return put({ url: `/{id}`, data })
// }
