import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  Table,
  Pagination,
  Breadcrumb,
  Divider,
  Collapse,
  Modal
} from "antd";
import { useNavigate, useParams} from 'react-router'
import { Helmet } from "react-helmet";
import PaginationShowingCount from '../component/table/PaginationShowingCount';
import PaginationChangeLimit from '../component/table/PaginationChangeLimit';
import Layout from "../component/layout/Layout";
import SkeletonLoading from '../component/SkeletonLoading';
import TableLoading from '../component/table/Loading';
import Notification from "../services/Notification";
import { itemRender } from '../utils/common'
import { getDeliveryTPV, getAddressTDS } from "../services/MegaforceUnitDelivery";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const App = () => {

  const initialized = useRef(false);
  const status = useRef("done");
  const [loadingFirst, setLoadingFirst] = useState(false);
  // const [dataError, setDataError] = useState(false);

  const navigate = useNavigate()
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [isStatusDone, setIsStatusDone] = useState(true);
  const [ddlTds, setDdlTds] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { id } = useParams()
  const Noti = new Notification()

  const itemMenu = [
    {
      href: `/megaforce-unit-delivery`,
      title: (
        <>
          <span>Megaforce Unit Delivery</span>
        </>
      )
    },
    {
      title: (
        <>
          <span>Megaforce Unit Delivery Detail</span>
        </>
      )
    }
 ]
  const Columns = [
    {
      title: 'Item',
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      sorter: false,
      width: 70,
      render: (text) => <div className="font16 px-text-center">{text || "-"}</div>
    },
    {
      title: 'Serial No.',
      dataIndex: 'serialNo',
      key: 'serialNo',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Shipping No.',
      dataIndex: 'shippingNo',
      key: 'shippingNo',
      sorter: false,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
  ]

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        fncGetAddressTDS()
        setLoadingFirst(true)
        fncGetDeliveryTPV()
      }
    },[]
  );

  const [dataList, setDataList] = useState([]);
  // const [docNameList, setDocNameList] = useState([]);
  let dataSource = dataList?.listSerialNo || []
  console.log(dataList)

  const fncGetAddressTDS = () => {
    getAddressTDS().then(res => {
      let data = res.data.resData
      data.map(x=>x.id = x.id.toString())
      setDdlTds(data)
    }).catch(error => {
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
    })
   }

  const fncGetDeliveryTPV = () => {
    setLoadingApi(true)
    getDeliveryTPV({"refId" : id, status:"done"}).then((res) => {
      let resData = res.data;
      if (resData.resultCode === "20000") {
        let items = resData.resData
        items = items.map((r, i) => {
          return { ...r, key: i }
        })
        setDataList(items[0] || [])
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
      }
      setLoadingFirst(false)
      setLoadingApi(false)
    }).catch((error) => {
      console.log("🚀 ~ getDeliveryTPV ~ error:", error.response?.data?.resultCode)
      if(error.response?.data?.resultCode == "40401"){
        setDataList([])
        setLoadingFirst(false)
        setLoadingApi(false)
        return
      }
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setLoadingFirst(false)
      setLoadingApi(false)
    });
  }


  return <>
  <Layout activeKey={'megaforce-unit-delivery'} >
    <Helmet>
        <title>[NodesNow] Megaforce Unit Delivery</title>
      </Helmet>
      <Breadcrumb
        itemRender={itemRender}
        items={itemMenu}
      />
      <Divider plain></Divider>
    {loadingFirst ? <SkeletonLoading /> : <>
      {
          <Card title="Megaforce Unit Delivery" style={{ textAlign: 'left' }} bordered={false} className='card-list'>
            <div className="list-done-container">
              <div className="list-done-detail-container">
                <div className="list-done-detail">
                  <div style={{minWidth:"150px"}}>Purchase Order :</div>
                  <Input style={{ maxWidth: "300px",flex:1}} readOnly value={dataList.purchaseOrderId}/>
                </div>
                <div className="list-done-detail">
                  <div style={{minWidth:"150px"}}>HBL :</div>
                  <Input style={{ maxWidth: "300px",flex:1}} readOnly value={dataList.hbl}/>
                </div>
                <div className="list-done-detail">
                  <div style={{minWidth:"150px"}}>Container :</div>
                  <Input style={{ maxWidth: "300px",flex:1}} readOnly value={dataList.container}/>
                </div>
                <div className="list-done-detail">
                  <div style={{minWidth:"150px"}}>Destination :</div>
                  <Input style={{ maxWidth: "300px",flex:1}} readOnly value={dataList.destination}/>
                </div>
                <div className="list-done-detail">
                  <div style={{minWidth:"150px"}}>Total unit :</div>
                  <Input style={{ maxWidth: "300px",flex:1}} readOnly value={dataList.listSerialNo?.length}/>
                </div>
                <div className="list-done-detail">
                  <div style={{minWidth:"150px"}}>Ref. Unit Delivery Id :</div>
                  <Input style={{ maxWidth: "300px",flex:1}} readOnly value={dataList.unitDeliveryId}/>
                </div>
              </div>
              <div className="back-btn">
                <Button type="primary" style={{minWidth: 150}} className="bt-main" onClick={() => {
                    navigate(`/megaforce-unit-delivery`)
                  }}>
                  Back
                </Button>
              </div>
            </div>
            <div style={{margin:"20px 0"}}>Serial No. :</div>
              {dataSource.length > 0 ? <>
                <div className="done-table">
                  <Table
                    key={'1'}
                    rowKey={record => record.key}
                    bordered
                    showSorterTooltip={false}
                    scroll={{ x: 300 }} 
                    columns={[
                      ...Columns,
                    ]
                    }
                    loading={{
                      spinning: loadingApi,
                      indicator: <TableLoading />
                    }}
                    locale={{ emptyText: <div> No Data </div> }}
                    pagination={false}
                    dataSource={dataSource}
                    size="small"
                  />
                </div>
              </> : <>
                <div className="no-data">No Data Found</div>
              </>}
            
          </Card>
      }
    </>
    }
  </Layout>
  </>;
};

export default App;
